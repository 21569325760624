<template>
  <div :class="$style.banner">
    <h1 :class="$style[`banner_${current.name || current.link}`]">
      {{ current.title }}
    </h1>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['current']),
  },
}
</script>

<style lang="less" module>
@banner-height: 5.19rem;

.banner {
  padding-top: @nav-height;

  @media screen and (max-width: @mini-width) {
    padding-top: @nav-mini-height;
  }

  h1 {
    .text-hide();
    height: @banner-height;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @media screen and (max-width: @mini-width) {
      @r: 2; // 放大倍数

      background-position: center;
      background-size: 19.2rem * @r @banner-height * @r;
    }
  }

  &_about {
    background-image: url('./images/title-banner-about.jpg');
  }

  &_honor {
    background-image: url('./images/title-banner-honor.jpg');
  }

  &_news {
    background-image: url('./images/title-banner-news.jpg');
  }

  &_game {
    background-image: url('./images/title-banner-game.jpg');
  }

  &_campus {
    z-index: 1;
    position: relative;
    background-image: url('./images/title-banner-campus.png');

    .banner & {
      height: 5.73rem;
      background-size: 19.2rem 5.73rem;
    }

    &::after {
      @slogan-width: 5.57rem;
      @slogan-left: 3.6rem;

      .size(@slogan-width, 3.07rem);
      content: '';
      position: absolute;
      top: 1.22rem;
      left: @slogan-left;
      background: url('./images/title-banner-campus-title.png') no-repeat;
      background-size: 100% 100%;

      @media screen and (max-width: @laptop-width) {
        left: @slogan-left - @campus-labtop-width / 2;
      }

      @media screen and (max-width: @tablet-width) {
        left: @slogan-left - @campus-tablet-width / 2;
      }

      @media screen and (max-width: @mini-width) {
        width: @slogan-width * 1.4;
        left: @slogan-left - @campus-mini-width / 2;
        background-size: 100% auto;
      }
    }
  }

  &_develop {
    background-image: url('./images/title-banner-develop.jpg');
  }

  &_benefit {
    background-image: url('./images/title-banner-benefit.jpg');
  }

  &_life {
    background-image: url('./images/title-banner-life.jpg');
  }

  &_contact {
    background-image: url('./images/title-banner-contact.jpg');
  }

  &_business {
    background-image: url('./images/title-banner-business.jpg');
  }

  &_complications {
    background-image: url('./images/title-banner-complications.jpg');
  }

  &_statement {
    background-image: url('./images/title-banner-statement.jpg');
  }
}
</style>
