export default {
  /**
   * head 数据
   */
  // 页面标题
  title: (state) => {
    const { current, base } = state
    return {
      title: current && current.title ? `${current.title}_` : '',
      titleTemplate: `%s${base.title || ''}官方网站`,
    }
  },
  // 网页描述
  meta: (state) => {
    const { base } = state
    const { keywords, description } = base || {}
    return [
      { hid: 'keywords', name: 'keywords', content: keywords || '' },
      {
        hid: 'description',
        name: 'description',
        content: description || '',
      },
    ]
  },
  // logo
  logo: (state) => {
    const { jzyx_base_logo: logsoData = [] } = state.source
    const logo = logsoData.find((el) => el.unique_id === 'home')
    return logo && logo.image
  },
  // 系统菜单
  system: (state) => {
    const { jzyx_base_menu: menuData = [] } = state.source
    let newObj = {}
    // 格式化数据结构
    menuData.forEach((el) => {
      newObj = { ...newObj, [el.unique_id]: { name: el.title, url: el.url } }
    })
    return newObj
  },
  // 极致官方二维码
  qrc: (state) => {
    const { base } = state
    return base.image || ''
  },
  // 联系信息
  contact: (state) => {
    const { base } = state
    const { phone = '', phone_cs: phoneCs = '' } = base || {}
    return { phone, phone_cs: phoneCs }
  },
  copyright: (state) => {
    const { base } = state
    const { copyright = '', copyright_name: copyrightName = '' } = base || {}
    return `Copyright © ${copyright} All Rights Reserved. ${copyrightName} 版权所有`
  },
  // 通过路由切换检查网站置灰类型，当仅首页时，需要手动处理
  grayIndex: (state) => {
    const { gray } = state.base
    return gray === 2 || gray === 4
  },

  /**
   * 当前页面数据
   */
  current: (state) => {
    return state.current || {}
  },

  /**
   * 游戏列表数据
   */
  games: (state) => {
    return state.games || []
  },

  /**
   * 企业文化内容
   */
  cultureData: (state) => {
    const { jzyx_about_culture_next: cultureData = [] } = state.source
    return (cultureData && cultureData[0]) || {}
  },
}
