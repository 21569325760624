const middleware = {}

middleware['current'] = require('../middleware/current.js')
middleware['current'] = middleware['current'].default || middleware['current']

middleware['device'] = require('../middleware/device.js')
middleware['device'] = middleware['device'].default || middleware['device']

middleware['games'] = require('../middleware/games.js')
middleware['games'] = middleware['games'].default || middleware['games']

middleware['source'] = require('../middleware/source.js')
middleware['source'] = middleware['source'].default || middleware['source']

export default middleware
