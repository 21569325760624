// 获取当前页面数据
import navData from '@/assets/js/navData'

export default function ({ store, route }) {
  const routeName = route.name.split('-')[0] // 从嵌套路由中获取上次路由

  store.dispatch('SetCurrent', getCurrent())

  function getCurrent(data = navData) {
    let obj
    for (const i in data) {
      if (data[i].menu) {
        obj = getCurrent(data[i].menu)
        if (obj) {
          obj = { ...obj, parent: data[i] }
          break
        }
        continue
      }
      const title = data[i].name || data[i].link || ''
      if (!title) continue
      if (title === routeName) {
        obj = data[i]
        break
      }
    }

    return obj
  }
}
