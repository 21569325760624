// Vue Awesome Swiper (https://github.com/surmon-china/vue-awesome-swiper)
import Vue from 'vue'
import {
  Swiper as SwiperClass,
  Navigation,
  Pagination,
  Autoplay,
  Lazy,
  EffectFade,
} from 'swiper/js/swiper.esm'

import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

// Swiper modules
SwiperClass.use([Navigation, Pagination, Autoplay, Lazy, EffectFade])

// Global use
Vue.use(getAwesomeSwiper(SwiperClass))
