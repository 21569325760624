<template>
  <div
    :class="[
      'app-container',
      $style.nav,
      active && $style.active,
      hide && $style.hide,
    ]"
    @mouseleave="onMouseleave"
  >
    <!-- Logo -->
    <the-nav-logo />

    <div :class="$style.nav_wrap">
      <!-- 一级导航 -->
      <ul :class="[$style.bar, $style.nav_main]">
        <the-nav-menu
          v-for="(nav, navIndex) in navData"
          :key="navIndex"
          :class="[$style.bar_list, isCurrent(nav) && $style.current]"
          :data="nav"
          @mouseenter="onMouseenter"
        />
      </ul>

      <!-- 二级导航 -->
      <div :class="[$style.bar, $style.nav_sub]">
        <div
          v-for="(nav, navIndex) in navData"
          :key="navIndex"
          :class="$style.bar_list"
        >
          <ul v-if="nav.menu">
            <the-nav-menu
              v-for="(menu, menuIndex) in nav.menu"
              :key="menuIndex"
              :data="menu"
              :class="isCurrent(menu) && $style.current"
            />
          </ul>
        </div>
      </div>
    </div>

    <!-- Mini 导航(小屏用) -->
    <div :class="$style.nav_wrap_mini">
      <the-nav-mini />
    </div>
  </div>
</template>

<script>
import navData from '@/assets/js/navData'
import TheNavLogo from './TheNavLogo'
import TheNavMenu from './TheNavMenu'
import TheNavMini from './TheNavMini'

export default {
  components: { TheNavLogo, TheNavMenu, TheNavMini },
  data() {
    return {
      navData,
      active: false, // 导航条下拉菜单激活状态
      hide: false, // 导航条显示/隐藏状态
      scrollTop: 0, // 记录最后一次的 scroll top 值, 作为滚动方向的判断条件
      timer: null, // 控制滚动条显示/隐藏的延迟触发器
      scrollEvent: null,
    }
  },
  watch: {
    $route(val) {
      // 当路由变化时收起下拉菜单
      this.active && (this.active = false)
    },
  },
  mounted() {
    this.scrollTop = this.getScrollTop()
    this.scrollEvent = () => {
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(this.handleScroll, 50)
    }
    window.addEventListener('scroll', this.scrollEvent, false)
  },
  destroyed() {
    if (this.timer) clearTimeout(this.timer)
    window.removeEventListener('scroll', this.scrollEvent)
  },
  methods: {
    isCurrent(nav) {
      const routeName = this.$route.name.split('-')[0]
      let flag = false
      if (nav.menu) {
        for (const i in nav.menu) {
          if (nav.menu[i].name === routeName) {
            flag = true
            break
          }
        }
        if (flag) return flag
      }
      nav.name === routeName && (flag = true)
      return flag
    },
    getScrollTop() {
      return document.body.scrollTop || document.documentElement.scrollTop
    },
    onMouseenter() {
      !this.active && (this.active = true)
    },
    onMouseleave() {
      this.active && (this.active = false)
    },
    handleScroll() {
      const scrollTop = this.getScrollTop()
      this.hide = scrollTop !== 0 && this.scrollTop <= scrollTop
      this.scrollTop = scrollTop
    },
  },
}
</script>

<style lang="less" module>
@sub-height: 2.25rem;
@border: 1px solid fade(#e9e9e9, 90%);
@bar-margin: 2.85rem;

.nav {
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: top 0.3s, opacity 0.3s;

  &_wrap {
    &_mini {
      display: none;
    }
  }

  /* 隐藏导航 */
  &.hide {
    top: -@nav-height;
    opacity: 0;
  }

  /* 当前页状态 */
  .current {
    font-weight: 700;
  }

  /* 一级导航 */
  &_main {
    z-index: 1;
    position: relative;
    height: @nav-height;
    background-color: #fff;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    span {
      position: relative;
      display: inline-block;
      padding: 0.07rem 0;

      &::after {
        .size(0, 0.03rem);
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: @primary;
        transition: width 0.1s;
      }
    }

    a {
      width: 100%;

      &:hover {
        span::after {
          width: 100%;
        }
      }
    }
  }

  /* 二级导航 */
  &_sub {
    position: absolute;
    top: @nav-height;
    left: 0;
    width: 100%;
    border-top: @border;
    background-color: fade(#fff, 90%);
    opacity: 0;
    transform: translate3d(0, -100%, 0);
    transition: opacity 0.3s, transform 0.3s;

    ul {
      .size(100%);
      padding: 1em 0;
      border-left: @border;
      box-sizing: border-box;
    }

    a:hover {
      background-color: @primary;
      color: #fff;
    }

    /* 激活二级导航 */
    .active & {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

.bar {
  display: flex;
  justify-content: center;

  &_list {
    width: 1.92rem;

    .nav_sub &:nth-child(2) {
      ul {
        border-right: @border;
      }
    }

    &:nth-child(3) {
      margin-right: @bar-margin;
    }

    &:nth-child(4) {
      margin-left: @bar-margin;
    }
  }
}

@media screen and (max-width: @mini-width) {
  .nav {
    &_wrap {
      display: none;

      &_mini {
        .size(100%, @nav-mini-height);
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        border-bottom: @border;
        background-color: #fff;
      }
    }
  }
}
</style>
