export default {
  // 设置站点源数据
  SET_SOURCE(state, data) {
    state.source = data || {}
    state.base = (data.jzyx_base_config || []).find(
      (el) => el.unique_id === 'primary'
    )
  },

  // 设置当前页面数据
  SET_CURRENT(state, data) {
    state.current = data
  },

  // 设置游戏列表数据
  SET_GAMES(state, data) {
    state.games = data
  },
}
