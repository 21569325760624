<template>
  <nuxt-link v-if="logo" to="index" :class="$style.logo">
    <img :src="logo" />
  </nuxt-link>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['logo']),
  },
}
</script>

<style lang="less" module>
@logo-width: 1.08rem;
@logo-height: 1.02rem;
@logo-img-size: 0.75rem;

.logo {
  .size(@logo-width, @logo-height);
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: @primary;

  img {
    .size(@logo-img-size);
  }

  @media screen and (max-width: @mini-width) {
    .size(@nav-mini-height * 1.4, @nav-mini-height * 1.25);
    right: auto;
    margin: 0;

    img {
      .size(@nav-mini-height);
    }
  }
}
</style>
