export default () => ({
  // 站点源数据
  source: null,

  // 基本数据(从源数据中提取)
  base: null,

  // 当前页面数据
  current: {},

  // 游戏列表数据
  games: null,
})
