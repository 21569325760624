<template>
  <div class="app-container" style="overflow: hidden;">
    <the-nav />
    <nuxt />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheNav from '@/components/TheNav'

export default {
  components: { TheNav },
  computed: {
    ...mapGetters(['title']),
  },
  head() {
    return {
      ...this.title,
    }
  },
}
</script>
