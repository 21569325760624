<!-- 二级导航菜单 -->
<template>
  <li :class="$style.menu" @mouseenter="$emit('mouseenter')">
    <a
      v-if="data.link"
      :href="system[data.link] && system[data.link].url"
      target="_blank"
    >
      <span>
        {{ data.title || (system[data.link] && system[data.link].name) }}
      </span>
    </a>
    <nuxt-link v-else :to="data.url">
      <span>{{ data.title }}</span>
    </nuxt-link>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    ...mapGetters(['system']),
  },
}
</script>

<style lang="less" module>
.menu {
  a {
    display: block;
    padding: 0.6em 0;
    color: #575757;
    text-align: center;
    text-decoration: none;
  }
}
</style>
