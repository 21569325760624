// 获取游戏列表数据
export default async function ({ app, store, $winstonLog }) {
  if (!store.state.games || Object.keys(store.state.games).length === 0) {
    try {
      const { data } = await app.$axios.get('/wbhome/gameList')
      return store.dispatch('SetGames', (data && data.data) || [])
    } catch ({ response }) {
      const { status, statusText, config } = response || {}
      $winstonLog.error(`${new Date()} ${config.url} ${status} ${statusText}`)
    }
  }
}
