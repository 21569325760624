export default [
  { name: 'index', url: '/' },
  {
    name: 'about',
    url: '/about',
    title: '关于极致',
    menu: [
      { name: 'about', url: '/about', title: '关于我们' },
      { name: 'honor', url: '/honor', title: '极致荣誉' },
      { name: 'news', url: '/news', title: '极致资讯' },
    ],
  },
  {
    name: 'game',
    url: '/game',
    title: '极致产品',
  },
  {
    link: 'job',
    title: '加入我们',
    menu: [
      { link: 'job' },
      { link: 'campus' },
      { name: 'develop', url: '/develop', title: '人才发展' },
      { name: 'benefit', url: '/benefit', title: '福利体系' },
      { name: 'life', url: '/life', title: '极致生活' },
    ],
  },
  {
    link: 'cs',
    title: '极致服务',
    menu: [
      { link: 'cs' },
      { link: 'account' },
      { link: 'pay' },
      { link: 'vip' },
      { link: 'dbg' },
    ],
  },
  {
    name: 'contact',
    url: '/contact',
    title: '联系我们',
    menu: [
      { name: 'contact', url: '/contact', title: '联系方式' },
      { name: 'business', url: '/business', title: '商务合作' },
      { name: 'statement', url: '/statement', title: '法律信息' },
      { link: 'jiazhang' },
      { name: 'complications', url: '/complications', title: '纠纷处理' },
    ],
  },
]
