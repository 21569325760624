import BrowserLogger from 'alife-logger'
import { version } from '../package.json'

export default ({ $config }) => {
  const { NUXT_ENV_API } = $config
  if (process.env.NODE_ENV === 'production') {
    BrowserLogger.singleton({
      pid: 'eg0azz190g@5ecbba815aa4e64',
      release: version,
      environment: !NUXT_ENV_API ? 'prod' : 'local',
      enableSPA: true,
      sendResource: true, // 上报页面静态资源
      useFmp: true, // 采集首屏FMP（First Meaningful Paint，首次有效渲染）数据
      ignore: {
        // 符合以下规则的 JS 错误不会被上报
        ignoreErrors: ['Network Error', '已取消', /^Script error\.?$/],
        // 忽略指定的资源错误
        ignoreResErrors: [
          function (str) {
            if (
              str &&
              (str.includes('//www.jzyx.com') || str.includes('file://'))
            )
              return true // 不上报
            return false // 上报
          },
        ],
      },
    })
  }
}
