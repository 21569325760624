export default {
  // 设置站点源数据
  SetSource({ commit }, data) {
    commit('SET_SOURCE', data)
  },

  // 设置当前页面数据
  SetCurrent({ commit }, data) {
    commit('SET_CURRENT', data)
  },

  // 设置游戏列表数据
  SetGames({ commit }, data) {
    commit('SET_GAMES', data)
  },
}
