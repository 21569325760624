<template>
  <div id="js-app" class="app-container" style="overflow: hidden;">
    <the-nav v-if="hasNav" :style="[hasParent && { display: 'none' }]" />
    <the-title-banner
      v-if="$route.name !== 'index' && hasBanner"
      :style="[hasParent && { display: 'none' }]"
    />
    <nuxt />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheNav from '@/components/TheNav'
import TheTitleBanner from '@/components/TheTitleBanner'

export default {
  components: { TheNav, TheTitleBanner },
  computed: {
    ...mapGetters(['title', 'meta', 'copyright', 'grayIndex']),
    // 网站是否被内嵌
    hasParent() {
      if (!process.server) {
        return window.self !== window.top
      }
      return false
    },
    // 通过路由传参控制导航的显示
    hasNav() {
      const { nav } = this.$route.query
      return !nav
    },
    // 通过路由传参控制 banner 的显示
    hasBanner() {
      const { ban } = this.$route.query
      return !ban
    },
  },
  mounted() {
    // eslint-disable-next-line
    console.log(`
    __      __________   ________ _____________________ ____________________
   /  \\    /  \\_____  \\  \\_____  \\\\______   \\_   _____//   _____/\\__    ___/
   \\   \\/\\/   //   |   \\  /   |   \\|    |  _/|    __)_ \\_____  \\   |    |
    \\        //    |    \\/    |    \\    |   \\|        \\/        \\  |    |
     \\__/\\  / \\_______  /\\_______  /______  /_______  /_______  /  |____|
          \\/          \\/         \\/       \\/        \\/        \\/
   `)

    // eslint-disable-next-line
    console.log(this.copyright)
  },
  head() {
    const { name } = this.$route
    let htmlAttrs = {}
    if (this.grayIndex) {
      if (name === 'index') {
        htmlAttrs = { style: 'filter: grayscale(100%)' }
      } else {
        htmlAttrs = { style: '' }
      }
    }

    return {
      ...this.title,
      meta: this.meta,
      htmlAttrs,
    }
  },
}
</script>
