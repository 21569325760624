import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3864e057 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _dc1b103e = () => interopDefault(import('../pages/benefit.vue' /* webpackChunkName: "pages/benefit" */))
const _7e8fe466 = () => interopDefault(import('../pages/business.vue' /* webpackChunkName: "pages/business" */))
const _0428da82 = () => interopDefault(import('../pages/complications.vue' /* webpackChunkName: "pages/complications" */))
const _95aa0a2c = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _b56d22b2 = () => interopDefault(import('../pages/develop.vue' /* webpackChunkName: "pages/develop" */))
const _eb4582d0 = () => interopDefault(import('../pages/game.vue' /* webpackChunkName: "pages/game" */))
const _4d73b2b4 = () => interopDefault(import('../pages/honor.vue' /* webpackChunkName: "pages/honor" */))
const _f3d73b92 = () => interopDefault(import('../pages/honor/index.vue' /* webpackChunkName: "pages/honor/index" */))
const _2d030ca2 = () => interopDefault(import('../pages/life.vue' /* webpackChunkName: "pages/life" */))
const _46f424f9 = () => interopDefault(import('../pages/news.vue' /* webpackChunkName: "pages/news" */))
const _28054cbc = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _48b74e24 = () => interopDefault(import('../pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _48bd7c39 = () => interopDefault(import('../pages/statement.vue' /* webpackChunkName: "pages/statement" */))
const _66c033fc = () => interopDefault(import('../pages/statement/index.vue' /* webpackChunkName: "pages/statement/index" */))
const _0b830564 = () => interopDefault(import('../pages/statement/_id.vue' /* webpackChunkName: "pages/statement/_id" */))
const _581cbb1c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _3864e057,
    name: "about"
  }, {
    path: "/benefit",
    component: _dc1b103e,
    name: "benefit"
  }, {
    path: "/business",
    component: _7e8fe466,
    name: "business"
  }, {
    path: "/complications",
    component: _0428da82,
    name: "complications"
  }, {
    path: "/contact",
    component: _95aa0a2c,
    name: "contact"
  }, {
    path: "/develop",
    component: _b56d22b2,
    name: "develop"
  }, {
    path: "/game",
    component: _eb4582d0,
    name: "game"
  }, {
    path: "/honor",
    component: _4d73b2b4,
    children: [{
      path: "",
      component: _f3d73b92,
      name: "honor"
    }]
  }, {
    path: "/life",
    component: _2d030ca2,
    name: "life"
  }, {
    path: "/news",
    component: _46f424f9,
    children: [{
      path: "",
      component: _28054cbc,
      name: "news"
    }, {
      path: ":id",
      component: _48b74e24,
      name: "news-id"
    }]
  }, {
    path: "/statement",
    component: _48bd7c39,
    children: [{
      path: "",
      component: _66c033fc,
      name: "statement"
    }, {
      path: ":id",
      component: _0b830564,
      name: "statement-id"
    }]
  }, {
    path: "/",
    component: _581cbb1c,
    name: "index"
  }, {
    path: "*",
    redirect: "/"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
