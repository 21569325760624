<template>
  <div :class="$style.error">
    <div>
      <h1>{{ error.statusCode }}</h1>
      <h2>页面数据异常</h2>
      <button v-if="error.statusCode !== 404" @click="$router.go(0)">
        刷新试试
      </button>
      <nuxt-link v-if="$route.path !== '/'" to="/">返回首页</nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'blank',
  props: {
    error: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>

<style lang="less" module>
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;

  h1 {
    margin-bottom: 0.5em;
    font-size: 1rem;
  }

  h2 {
    margin-bottom: 1em;
    font-size: 0.6rem;
  }

  a,
  button {
    display: inline-block;
    margin: 0 1em;
    color: @text-color;

    &:hover {
      color: @primary;
    }
  }

  button {
    background-color: transparent;
    text-decoration: underline;
  }
}
</style>
