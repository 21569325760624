// JZ General (https://stl.woobest.com/wiki/pages/viewpage.action?pageId=69067130)
import JZGeneral from '@jz/general'

export default ({ store, query, $config }) => {
  const { source } = store.state
  const { NUXT_ENV_API } = $config
  JZGeneral(
    { debug: NUXT_ENV_API, extendId: 'jzyx_about_culture_next' },
    (generalObj) => {
      // 当服务器未请求到数据时，将插件获取到的数据存储起来
      if (!source) store.dispatch('SetSource', generalObj.source)
      // 通过路由传参控制 footer 的显示
      const { foot } = query
      window.self === window.top && !foot && generalObj.footer({ top: true })
    }
  )
}
