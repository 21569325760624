<template>
  <div :class="$style.mini">
    <button
      jz-home-event="toggle"
      :class="[$style.toggle, active && $style.toggle_active]"
      @click="onToggle"
    >
      <i />
      <i />
      <i />
    </button>
    <ul :class="[$style.list, active && $style.list_active]">
      <the-nav-menu v-for="(el, index) in list" :key="index" :data="el" />
    </ul>
  </div>
</template>

<script>
import TheNavMenu from './TheNavMenu'

export default {
  components: { TheNavMenu },
  data() {
    return {
      active: false,
      list: [
        { name: 'index', url: '/', title: '首页' },
        { link: 'account' },
        { link: 'vip' },
        { link: 'cs' },
        { name: 'about', url: '/about', title: '关于我们' },
        { name: 'game', url: '/game', title: '极致产品' },
        { name: 'business', url: '/business', title: '商务合作' },
        { link: 'job' },
        { link: 'campus' },
      ],
    }
  },
  methods: {
    onToggle() {
      if (!this.active) {
        this.active = true
        setTimeout(() => {
          document.body.addEventListener('click', this.handleGlobalClick)
        }, 100)
      } else {
        this.hideList()
      }
    },
    hideList() {
      this.active = false
      document.body.removeEventListener('click', this.handleGlobalClick)
    },
    handleGlobalClick(e) {
      const parent = this.getParentByAttribute(e.target, 'jz-home-event')
      if (!parent) {
        this.hideList()
      }
    },
    /**
     * 通过属性获取父元素
     * @param {Element} element
     * @param {string} attribute
     * @return {Element}
     */
    getParentByAttribute(element, attribute) {
      let parent
      for (; element && element !== document; element = element.parentNode) {
        const attrs = Array.from(element.attributes)
        if (attrs.length > 0) {
          const found = attrs.find((elem) => elem.name === attribute)
          if (found) {
            parent = { target: element, type: found.value }
            break
          }
        }
      }
      return parent
    },
  },
}
</script>

<style lang="less" module>
.toggle {
  @btn-height: 0.2rem;
  @btn-padding: 0.1rem;

  .size(@nav-mini-height);
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;

  i {
    .size(@nav-mini-height -  @btn-padding * 5, @btn-height);
    position: absolute;
    right: @btn-padding * 3;
    background-color: #ccc;
    transition: all 0.3s;

    &:first-child {
      top: @btn-height * 2;
    }

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }

    &:last-child {
      bottom: @btn-height * 2;
    }
  }

  &_active {
    i {
      &:first-child {
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:last-child {
        bottom: 50%;
        transform: translateY(50%) rotate(45deg);
      }
    }
  }
}

.list {
  display: none;
  position: absolute;
  top: @nav-mini-height;
  right: 0;
  padding: 1em 0;
  border-top: 1px solid fade(#e9e9e9, 90%);
  background-color: fade(#fff, 90%);
  font-size: 15px;

  &_active {
    display: block;

    :global {
      animation: fadeInLeft 1s both;
    }
  }

  li {
    a {
      padding: 0.7em 3.5em;
    }
  }
}
</style>
