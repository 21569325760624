// 获取网站站点源数据
export default async function ({ app, store, $winstonLog }) {
  if (!store.state.source || Object.keys(store.state.source).length === 0) {
    try {
      const { data } = await app.$axios.get('/cms/archives_data', {
        params: {
          unique_id:
            'jzyx_base_config,jzyx_base_logo,jzyx_base_menu,jzyx_about_culture_next',
        },
      })
      return data.data && store.dispatch('SetSource', data.data)
    } catch ({ response }) {
      const { status, statusText, config } = response || {}
      $winstonLog.error(
        `${new Date()} ${config.baseURL}${config.url} ${status} ${statusText}`
      )
    }
  }
}
